import { moment } from '../../../../boot'
import { Table } from '../../../../components'
import { installmentStatus } from '../../../../constants'
import { Col } from 'react-bootstrap'
const NewInstallment = ({ data = {} }) => {
    // if(Object.keys(data)?.length === 0) return <p>شما اقساطی ندارید</p>
    const showStatus = (id = 0) => {
        const { color, name } = installmentStatus.find((e) => e.id === id) ?? {};
        return <span className={`text-${color} fw-bold`}>{name}</span>;
    };
    const showInfo = [
        {
            label: "مبلغ کل اقساط",
            value: data?.totalInstallmentsAmount ? `${data?.totalInstallmentsAmount?.toLocaleString()} تومان` : 0
        },
        {
            label: "تعداد کل اقساط" ,
            value: data?.installments ? data?.installments?.length : 0
        },
        {
            label: "اقساط پرداخت نشده" ,
            value: data?.unpaidInstallmentsCount ? data?.unpaidInstallmentsCount : 0
        },
        {
            label: "اقساط پرداخت شده",
            value: data?.paidInstallmentsCount ? data?.paidInstallmentsCount : 0
        },
        {
            label: "دسترسی هر قسمت",
            value: data?.installments ? data?.installments[0]?.accessSpot : 0
        },
        {
            label: "دسترسی تا به الان",
            value: data?.accessSpotCourse ? data?.accessSpotCourse : 0
        },

    ]
    return (
        <div className="w-full">
            <div className="row rounded shadow-sm w-full my-4 p-1 ">
                {showInfo?.map((i) =>
                    <Col style={{maxHeight : "6rem" , minHeight : "4rem"}} className=" p-2" key={i?.label} xs="5" lg="3" xl="2">
                        <p className='fw-700 text-black fs-16'>{i?.label}</p>
                        <p className='fw-500 text-black fs-14 '>{i?.value}</p>
                    </Col>
                )}
            </div>
            {Object.keys(data || {})?.length > 0
                ?
                <Table>
                    <thead>
                        <tr>
                            <th>تاریخ سر رسید</th>
                            <th>مبلغ</th>
                            <th>وضعیت</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data?.installments?.map((e) =>
                            <tr key={e?._id}>
                                <td>
                                    <span dir="ltr">
                                        {moment.miladiToShamsi({
                                            date: e?.dueDate,
                                            format: "jYYYY/jMM/jDD",
                                        })}
                                    </span>
                                </td>
                                <td>{`${e?.amount?.toLocaleString()} تومان`}</td>
                                <td>{showStatus(e?.status)}</td>
                            </tr>
                        )}
                    </tbody>
                </Table>
                :
                <p>کاربر اقساطی ندارد</p>
            }
        </div>
    )
}

export default NewInstallment